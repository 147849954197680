import React from "react";
import registerInterestImage from "./../assets/image/svg/register-icon.svg"
import orderImage from "./../assets/image/svg/register-order.svg"
import { Button, Section } from "../components/Core";
import { navigate } from "gatsby"
import { Container, Col, Row } from "react-bootstrap";
import Seo from "../components/Seo";

const SuccessPage = (props) => {
    return <>
        <Seo page="success" />
        {
        props?.location?.state?.status === 'ORDER' ?
        <Section className="green-bg text-center">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8} md={10} sm={8}>
                        <img src={orderImage} className="mt-30 mb-30" />
                        <h2>Success! You placed your order<br />and your installation is now scheduled.</h2>
                        <Button
                            onClick={() => {
                                navigate('/');
                            }}
                            className="mt-50"
                        >Return to homepage</Button>
                    </Col>
                </Row>
            </Container>
        </Section>
        :
        <Section className="green-bg text-center">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8} md={10} sm={8}>
                        <img src={registerInterestImage} className="mt-30 mb-30" />
                        <h2>Unfortunately, YouFibre is not yet available in your area.</h2>
                        <p>We are continuously expanding our networking, with around 50,000 new homes being added to our network every month.</p>
                        <p>
                            You are now eligible to receive 10% off your first contract with us when we are available to your home.
                            We will email you the moment that our network is live in your area with details on how to claim your early bird discount.
                        </p>
                        <Button
                            className="mt-50 light-bg"
                            onClick={() => {
                                navigate('/');
                            }}
                        >Return to homepage</Button>
                    </Col>
                </Row>
            </Container>
        </Section>
        }
    </>
}

export default SuccessPage